export class AuthUserModel {
  id: number;
  role: AuthUserRoleEnum;
  status: string;
  phoneNumber: string;
  email: string;
  username: string;
  salesAccountId: any;
  firstName: string;
  lastName: string;
  middleName: string;
  contactName: string;
  aaVersion: number;
  currency: string;
  permissions: string[];
  deviceHash: string;
  token: string;
  tfaType: VerificationOptionEnum;
  secretPin: string;
  securityAnswer: string;
  securityQuestion: string;
  dateCreated: string;
  dateModified: string;
  nameSuffix: string;
  preferences: any[];
  requirements: AuthUserRequirementsEnum[];
  pendingEmail: string | null;

  static fromJson(json: any): AuthUserModel {
    return {
      id: json.id,
      role: json.role,
      status: json.status,
      phoneNumber: json.phone_number,
      email: json.email,
      username: json.username,
      salesAccountId: json.sales_account_id,
      firstName: json.first_name,
      lastName: json.last_name,
      middleName: json.middle_name,
      contactName: json.contact_name,
      aaVersion: json.aa_version,
      currency: json.currency,
      permissions: json.permissions,
      token: json.token,
      deviceHash: json.device_hash,
      tfaType: json.tfa_type,
      secretPin: json.secret_pin,
      securityAnswer: json.security_answer,
      securityQuestion: json.security_question,
      dateCreated: json.date_created,
      dateModified: json.date_modified,
      nameSuffix: json.name_suffix,
      pendingEmail: json.pending_email,
      preferences: json.preferences,
      requirements: json.requirements
    };
  }
}

export interface ILoginReqBody {
  username: string;
  password: string;
  ipTracking?: boolean;
}

export class LoggedInStateModel {
  constructor(
    public readonly value: boolean,
    public readonly reason: UserLoggedInReasonEnum
  ) {}
}

export enum UserLoggedInReasonEnum {
  Init = 'INIT',
  UserAction = 'USER_ACTION',
  StandAlone = 'STANDALONE'
}

export enum AuthUserRoleEnum {
  Admin = 'admin',
  Customer = 'customer',
  Manager = 'manager',
  Office = 'office',
  Qa = 'qa',
  Banner = 'banner',
  Microservice = 'microservice',
  Csr = 'csr',
  Overflow = 'overflow'
}

export enum VerificationOptionEnum {
  Email = 'email',
  App = 'app'
}

export enum AuthUserRequirementsEnum {
  AuthPhoto = 'auth photo',
  Id = 'id'
}
